import CONFIG from 'config'

export const getNewConfigList = async () => {
  const response = await fetch(`${CONFIG.BOSSJOB_CONFIG_URL}/ph/list`)
  const { data } = await response.json()
  return data
}

export const getNeptuneCompanyConfigList = async () => {
  const token = localStorage.getItem('accessToken')
  const response = await fetch(
    'https://dev.api.bossjob.com/neptune-admin/config?filters=company_operating_status_lists,company_type_lists,country_lists',
    {
      method: 'GET', // or 'POST'
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  )
  const { data } = await response.json()
  return data
}

// https://dev.api.bossjob.com/config/ph/list?language_code=en
